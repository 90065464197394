@import "../../styling/color"
@import "../../styling/rwd"

.favorite-button
	display: inline-flex
	align-items center
	padding: 6px 8px 
	border: 0
	border-radius: 4px
	background-color: $dark-gray
	cursor: pointer
	transition: background-color 0.2s ease

	&:hover
		background-color: $selected-orange

	&--active
		path
			fill: $favorite-red
			transition: fill 0.2s ease

	&__text
		width: 48px
		margin-left: 4px
		font-size: 12px
		line-height: 1
		font-weight: 600
		color: $white

		+rwd-mobile()
			display: none
