@import "../../../styling/rwd"
@import "../../../styling/params"
@import "../../../styling/color"

.nav-comic
	position: fixed
	left: 0
	top: 0
	width: 100vw
	padding: 0 $layout-padding
	line-height: $layout-nav-height
	min-height: $layout-nav-height
	color: $white
	background-color: $header-grey
	z-index: 5

	+rwd-mobile()
		padding: 0 $layout-md-padding

	&__area
		display: grid
		grid-template-columns: minmax(140px, 0.85fr) minmax(200px, 1fr) 160px
		grid-template-areas: "logo episode menu"

		+rwd-mobile()
			grid-template-columns: auto 100px
			grid-template-areas: "logo menu"

	&__logo-area
		grid-area: logo
		display: flex

	&__logo
		cursor: pointer
		padding: 0 8px

	&__comic-name
		cursor: pointer
		padding: 0 8px

		&:hover
			color: $hover-orange

	&__episode
		grid-area: episode
		display: flex

		&__prev, &__next
			display: flex
			align-items: center
			cursor: pointer

			svg
				transform: scale(0.8)
			path
				fill: currentcolor

			&:not(.nav-comic__episode--disable)
				color: $selected-orange
				&:hover
					color: $hover-orange

		&__prev
			margin-right: 8px

		&--disable
			color: $grey40
			cursor: not-allowed

	&__menu
		grid-area: menu
		display: flex
		align-items center
		justify-content: flex-end

		&__toggle-list
			margin-left: 16px
			padding-left: 12px
			padding-right: 12px
			cursor: pointer

			&--active
				rect
					fill: $selected-orange

			&:hover
				rect
					fill: $hover-orange
	
	&--text-overflow
		max-width: 100px
		margin: 0 8px 
		overflow: hidden
		white-space: nowrap
		text-overflow: ellipsis
		+rwd-tablet()
			max-width: 60vw
		+rwd-mobile()
			max-width: 55vw
	
	&__episode-list
		position: fixed
		right: 0
		top: $layout-nav-height
		--nav-height: $layout-nav-height
		height: calc(100vh - var(--nav-height))
		max-width: 0
		background-color: rgba(0, 0, 0, 0.5)
		overflow: auto
		transition: all 0.3s ease-in-out

		&--open
			max-width: 30vw
			+rwd-mobile()
				max-width: 55vw

		&__item
			width: 20vw
			min-width: 200px
			padding: 0 16px
			margin-left: auto
			border-bottom: 1px solid $white
			background-color: rgba(56, 56, 56, 0.85)
			text-align: center
			overflow: hidden
			white-space: nowrap
			text-overflow: ellipsis
			cursor: pointer

			&--active
				background-color: rgba(252, 111, 1, 0.85)
			
			&:hover
				color: $hover-orange
