@import "../../../styling/color"

.nav-sider
	position: fixed
	top: 0
	left: 0
	max-width: 0
	width: 100%
	height: 100vh
	background-color $dark-gray
	transition: max-width 0.5s ease-in-out
	overflow: hidden

	&--active
		max-width: 86%
		
	&__close-button
		height: 64px
		display: flex
		align-items: center
		justify-content: flex-end
		>svg
			box-sizing: content-box
			padding: 8px
			cursor: pointer
			&:hover, &:focus
				path
					stroke: $hover-orange
		
	&__menu, .ant-menu
		background-color: inherit
	
	&__menu
		height: calc(100% - 64px)
		border: none
		overflow: auto
		-ms-overflow-style: none
		scrollbar-width: none
		&::-webkit-scrollbar
			display: none
	
	&__user
		.ljit-user-avatar__username
			color: $white
			font-size: 14px
		&__icon
			margin-right: 12px

	&__separate-line
		width: 90%
		height: 1px
		background-color: $grey40
		margin: 8px auto

	&__category-title
		margin: 24px 40px
		color: $white
		font-size: 12px

	&__item
		display: flex
		align-items: center
		color: $white
		svg path
			stroke: currentcolor
			fill: currentcolor
		&--active
			color: $orange

	.ant-menu-submenu-title, .ant-menu-item
		padding: 0 40px !important
		height: auto !important
		&:hover, &:focus ,&:active
			background-color: $grey40

	.ant-menu-submenu-title
		color: $white
		font-size: 16px
		&:hover
			color: $hover-orange

	.ant-menu-item
		display: flex
		align-items: center
		color: $text-default-grey
		&:active
			color: $orange
		&:hover
			color: $hover-orange
			>.nav-sider__item
				color: inherit
		&.ant-menu-item-selected
			.nav-sider__item
				color: inherit

	.ant-menu-submenu-arrow
		right: 12% !important

	.ant-menu-submenu-open
		.ant-menu-submenu-title
			background-color: $grey40
		.ant-menu-item-selected
			background-color: transparent
			color: $orange
	
	.ant-menu-submenu-active
		color: #bfbfbf
