@import "../../styling/color"

.search-bar
	width: 100%
	background-color $grey40
	border: 1px solid $text-default-grey
	border-radius: 15px
	>.ant-input
		color: $white
		background-color: transparent
		border: none
		border-radius: 15px
		&:focus, &:hover
			box-shadow: 0 0 4px 1px $hover-orange

	&__button
		cursor: pointer
		&:focus, &:hover, &:active
			path
				stroke: $hover-orange
