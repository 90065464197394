@import "../color"
@import "../rwd"
@import "../params"

html, body
	background-color: $light-black

.layout
	min-height: 100vh
	background-color: inherit

	.ljit-layout
		background-color: inherit

	&__header
		min-height: $layout-nav-height
		position: fixed
		width: 100%
		z-index: 5
		padding: 0 $layout-padding
		background-color: $header-grey
		+rwd-mobile()
			padding: 0 $layout-md-padding
			min-height: $layout-nav-height + $layout-sub-nav-height
		+rwd-tablet()
			min-height: $layout-nav-height + $layout-sub-nav-height

	&__content
		min-height: calc(100vh - 212px)
		padding: $layout-padding
		margin: $layout-nav-height 0 0 0
		background-color: inherit
		+rwd-mobile()
			padding: $layout-padding $layout-md-padding
			margin-top: $layout-nav-height + $layout-sub-nav-height
		+rwd-tablet()
			margin-top: $layout-nav-height + $layout-sub-nav-height

.ant-message
	top: 44px

	.ant-message-notice-content
		box-shadow: 0 0 8px 0  #00000026

	.ljit-notify
		border-width: 1px
		padding: 8px 10px 8px 17px
		border-color: $grey40
		background-color: $header-grey

		> .anticon
			margin-right: 9px

		&__content
			color: $white
			font-size: 16px
			min-width: 252px
			max-width: 80vw
			padding-right: 16px

		&__close
			height: 22px

			> .anticon
				margin: auto

		.ljit-icon--middle, .ljit-icon--middle>svg
			width: 18px
			height: 18px

		.ljit-icon-button svg
			fill: #bfbfbf

.ljit-icon
	&.anticon-loading
		color: white

.ant-modal
	.ant-modal-header
		padding: 12px 20px 11px

	.ant-modal-title
		line-height: 24px

	.ant-modal-footer
		padding: 7px 16px 8px

		> div > button
			padding: 0 11px

	.ant-modal-close-x
		display: none

.ljit-message-modal
	.ant-modal-body
		padding: 15px 20px 35px
		color: rgba(0,0,0,.85)

.ms-table
	width: 100%

	.ant-table
		margin-bottom: 12px

	.ant-table-empty .ant-table-tbody
		min-height: 0

	colgroup
		width: 100%
		display: block

	.ant-table-thead, .ant-table-tbody > tr
		display: table
		width: 100%
		table-layout: fixed
	
	.ant-table-tbody
		border-bottom: 1px solid #e8e8e8
		max-height: calc(100vh - 356px)
		min-height: 150px
		overflow: auto
		display: block
		width: 100%

		& > tr > td
			border-color: #e8e8e8
			padding-top: 4px
			padding-bottom: 4px

		& > tr:last-child > td
			border: none

	.ant-table-pagination 
		margin: 0

		.ant-pagination-next,
		.ant-pagination-prev,
		.ant-pagination-item
			line-height: 20px
			width: 26px
			min-width: 26px
			height: 26px

		.ant-pagination-prev,
		.ant-pagination-item
			margin-right: 4px

		.ant-pagination-item
			padding: 2px 0

		.ant-pagination-total-text
			height: 20px
			padding: 2px 0
			line-height: 20px
			margin-right: 4px
			text-align: right
			margin-top: 1px
