@import "../styling/rwd"
@import "../styling/params"

.layout__header
	.ant-dropdown-trigger
		cursor: pointer

	&--hidden
		display: none;

.layout__content	
	
	&.layout__comic-content
		margin-top: $layout-nav-height
		padding: 0

		+rwd-tablet()
			padding: 0 $layout-padding
