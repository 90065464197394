@import "../../../styling/rwd"
@import "../../../styling/color"

.nav-top-bar
	position: relative
	display: grid
	grid-template-columns: 40px minmax(100px, 1fr) 40px
	grid-template-areas: "hamburger logo search"
	align-items: center
	+rwd-laptop()
		max-width: 960px
		margin: auto
		grid-template-columns: 180px minmax(100px, 2fr) minmax(100px, 1fr) 140px
		grid-template-areas: "logo categories search user"
	+rwd-desktop()
		max-width: 1200px
		margin: auto
		grid-template-columns: 180px minmax(100px, 3fr) minmax(100px, 1.2fr) 140px
		grid-template-areas: "logo categories search user"

	&__sider-trigger
		grid-area: hamburger
		justify-self: start
		padding: 8px
		box-sizing: content-box
		cursor: pointer
		+rwd-xs-mobile()
			margin-left: -4px

		&:active, &:hover, &:focus
			rect
				fill: $hover-orange

	&__logo
		grid-area: logo
		place-self: center
		cursor: pointer
		padding: 0 8px

	&__search-bar-trigger
		grid-area: search
		justify-self: end
		padding: 8px
		box-sizing: content-box
		cursor: pointer
		+rwd-xs-mobile()
			margin-right: -4px

		&:active, &:hover, &:focus
			path
				stroke: $hover-orange

	&__categories
		grid-area: categories
		display: flex
		align-items: center
		margin-right: 12px
		+rwd-laptop()
			overflow: scroll
			min-height: 36px
			-ms-overflow-style: none
			scrollbar-width: none
			&::-webkit-scrollbar
				display: none

		&__item
			flex-shrink: 0
			margin: 0 4px
			+rwd-laptop()
				line-height: 24px
				padding: 0 8px
			+rwd-desktop()
				line-height: 2
				padding: 0 16px

			&--active
				background-color: $orange

	&__search-bar
		grid-area: search
		margin-right: 12px

	&__user
		grid-area: user
		width: 100%

		.ljit-user-avatar
			justify-content: center

			&__avatar
				margin-right: 8px

			&__content
				flex-shrink: 0
				
				+rwd-laptop()
					
					max-width: 80px 
				+rwd-desktop()
					max-width: 80px

			&__username
				color: $white
				white-space: nowrap

				+rwd-laptop()
					text-overflow: ellipsis
					overflow: hidden

			&:hover
				background-color: $dark-gray

	&__personal-setting-dropdown
		background-color: $dark-gray

		.ant-dropdown
			z-index: 999

		.ant-menu
			background-color: inherit
			margin-top: -4px

		.ant-menu-item
			background-color: inherit
			padding: 0 !important
			margin: 0 !important
			font-size: 14px
			height: 56px
			line-height: 2
			display: flex
			align-items: center
			justify-content: center

			>svg
				margin-right: 10px

			>a
				color: $white

			&:hover
				background-color: $orange
