@import "../../styling/color"
@import "../../styling/rwd"

.go-to-top
	position: fixed
	width: 100vw
	bottom: 15vh
	z-index: 99

	&__wrapper
		position relative

	&__circle
		position: absolute
		right: 8px
		width: 48px
		height: 48px 
		display: flex
		justify-content: center
		align-items: center
		border-radius: 50%
		border: 2px solid $hover-orange
		background-color: $light-black
		cursor: pointer

		+rwd-laptop()
			right: -24px

		&:hover
			.go-to-top__arrow
				animation: fade-in-out 1s ease-in-out 0.2s infinite,
				slide-up 1s ease-in-out 0.2s infinite

	&__arrow
		path
			fill: $hover-orange
			stroke: $hover-orange

@keyframes fade-in-out
	0%
		opacity: 0
	33%
		opacity: 1
	100%
		opacity: 0.1

@keyframes slide-up
	0%
		transform: translateY(4px)
	100%
		transform: translateY(-6px)
