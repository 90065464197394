@import "../../../styling/color"

.nav-item
	flex-shrink: 0 
	border-radius: 15px
	color: $white
	font-size: 16px
	cursor: pointer
	&:hover
		color: $hover-orange
		border-color: currentcolor
