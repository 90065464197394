@import "../../../styling/color"
@import "../../../styling/rwd"
@import "../../../styling/params"

.nav-top-sub-bar
	position: relative
	left: - $layout-padding
	display: flex
	align-items: center
	width: 100vw
	min-height: $layout-sub-nav-height
	overflow: scroll
	color: $white
	font-size: 16px
	line-height: 24px
	background-color $header-sub-grey
	-ms-overflow-style: none
	scrollbar-width: none
	&::-webkit-scrollbar
		display: none
	+rwd-mobile()
		left: - $layout-md-padding

	&__item
		flex-shrink: 0
		padding: 0 12px
		margin: 0 4px
		&--active
			background-color: $orange

	&__search-bar
		margin: 8px $layout-padding
		+rwd-mobile()
			margin: 8px $layout-md-padding
