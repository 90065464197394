// rwd breakpoint
$xs = 380px
$sm = 600px
$md = 900px
$lg = 1200px
$xl = 1536px

rwd-xs-mobile()
	@media screen and (max-width: $xs)
		{block}

rwd-mobile()
	@media screen and (max-width: $sm)
		{block}

rwd-tablet()
	@media screen and (min-width: $sm+1 ) and (max-width: $md)
		{block}

rwd-laptop()
	@media screen and (min-width: $md+1 ) and (max-width: $lg)
		{block}

rwd-desktop()
	@media screen and (min-width: $lg+1 )
		{block}
